(function () {
  // Versions of Edge below 15 need identifying so that we can serve them
  // alternative CSS for any text-stroke effects.
  var EDGE_RE = (/Edge\/(\d+)\./); //

  document.addEventListener('DOMContentLoaded', () => {
    var ua = navigator.userAgent + '';

    if (ua.indexOf('Safari') > -1 && ua.indexOf('Chrome') === -1) {
      document.body.classList.add('safari');
    }

    if ( !(EDGE_RE).test(ua) ) { return; }

    ua.replace(EDGE_RE, (_, version) => {
      document.body.classList.add('edge');
      if (Number(version) < 15) {
        document.body.classList.add('edge-14');
      }
    });
  });
})();
