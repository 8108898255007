import { createElement } from './util.js';
let Post = {};

Post.Typography = {
  SMALL_CAPS_WORDS: 3,

  setup: function () {
    this.fixInlineCodeWrap();
    this.makeSmallCaps();
  },

  fixInlineCodeWrap: function () {
    function fixCode (el) {
      let text = el.innerText;
      // Unless the code block is especially large, we want to prohibit it
      // from wrapping across two lines.
      if (text.length < 20) {
        el.classList.add('u-no-wrap');
      }
    }

    let codes = document.querySelectorAll('.post--article :not(pre) > code');
    [...codes].forEach(c => fixCode(c));
  },

  makeSmallCaps: function () {
    function makeSmallCapsNode (value) {
      let node = createElement('span', { 'class': 'u-smallcaps' });
      node.appendChild( document.createTextNode(value) );
      return node;
    }

    function splitAtFirstNWords (value, count) {
      let leadingSpace = '';
      let trailingSpace = '';
      let m = value.match(/^(\s+)/);
      if (m) { leadingSpace = m[1]; }

      m = value.match(/(\s+)$/);
      if (m) { trailingSpace = m[1]; }

      let words = value.trim().split(' ');
      let start = words.slice(0, count);
      let end = words.slice(count);

      let result = {
        count: start.length,
        start: start.length === 0 ? leadingSpace : leadingSpace + start.join(' '),
        end:   end.length === 0 ? null : ` ${end.join(' ')}${trailingSpace}`
      };

      if (!result.end) { result.start += trailingSpace; }

      return result;
    }

    function make (bq) {
      let walker = document.createTreeWalker(bq, NodeFilter.SHOW_TEXT);
      let quota = this.SMALL_CAPS_WORDS;
      let total = 0;

      let pending = [];

      while ( (total < quota) && walker.nextNode() ) {
        let node = walker.currentNode;
        if ( node.nodeValue.blank() ) { continue; }

        // Try to split on as many words as we have left to smallcap.
        let { count, start, end } = splitAtFirstNWords(node.nodeValue, quota - total);

        // `count` tells us how many we actually were able to split on.
        total += count;

        let item = { node };

        if (start) { item.start = makeSmallCapsNode(start);     }
        if (end)   { item.end   = document.createTextNode(end); }

        // Amazingly, we have to wait until later to do this stuff, because you
        // can't modify the result set of a TreeWalker without screwing it up.
        // This is ridiculous.
        pending.push(item);
      }

      pending.forEach((item) => {
        let { node, start, end } = item;
        if (start) { node.parentNode.insertBefore(start, node); }
        if (end)   { node.parentNode.insertBefore(end,   node); }
        node.parentNode.removeChild(node);
      });
    }

    let bqs = document.querySelectorAll('.post--quotation blockquote');
    [...bqs].forEach(make, this);
  }
};

Post.Share = {
  // Distance, in pixels, that the element should be from the bottom of the
  // viewport before we trigger load.
  THRESHOLD: 400,

  _scripts: {
    facebook: '//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.6'
  },

  setup: function () {
    this.element = document.querySelector('[data-share-links]');
    if (!this.element) return;

    this.load = this.load.bind(this);
    this.loadScript = this.loadScript.bind(this);

    let options = {
      root: null,
      rootMargin: `0px 0px ${this.THRESHOLD}px 0px`,
      threshold: 0.01
    };

    this.observer = new IntersectionObserver(this.load, options);
    this.observer.observe(this.element);
  },

  loadScript: function (name) {
    if ( document.querySelector(`[data-script-name="${name}"]`) ) return;
    let script = createElement('script', {
      'data-script-name': name,
      src: this._scripts[name]
    });
    document.body.appendChild(script);
  },

  load: function (entries) {
    if ( !entries.some(e => e.isIntersecting) ) { return; }
    Object.keys(this._scripts).forEach(this.loadScript);
  }
};

document.addEventListener('DOMContentLoaded', () => {
  Post.Share.setup();
  Post.Typography.setup();
});
