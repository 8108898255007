import { on, createElement } from './util';

const Lightbox = (function () {

  function viewportOffset (el) {
    let { left, top } = el.getBoundingClientRect();
    return { left, top };
  }

  function clonePosition (element, target) {
    let targetWidth = target.clientWidth;
    let borderTop = (target.offsetHeight - target.clientHeight) / 2;
    let borderLeft = (target.offsetWidth - target.clientWidth) / 2;
    let to = viewportOffset(target);

    let imgWidth = element.clientWidth;
    let ratio = targetWidth / imgWidth;

    let transform = `translate(${to.left + borderLeft}px, ${to.top + borderTop}px) scale(${ratio}, ${ratio})`;

    element.style.transformOrigin = 'top left';
    element.style.transform = transform;
  }

  function makeLightboxOpener (lightbox, element, overlay, dummy) {
    return (() => {
      clonePosition(lightbox, element);
      // Force the style batch to be purged.
      lightbox.offsetWidth;
      element.offsetWidth;

      // We've got three images:
      //
      // 1. The image that was clicked on.
      // 2. The image that we're using in the lightbox.
      // 3. The dummy invisible image.
      //
      // With CSS handling the animation, our tasks for showing and hiding
      // are really easy. The lightbox image shows and hides by borrowing the
      // positions and dimensions of whichever image (#1 or #3) it wants to
      // animate toward.
      //
      requestAnimationFrame(() => {
        overlay.classList.remove('lightbox__overlay--hidden');
        lightbox.classList.add('lightbox--animating');

        // Force the style batch to be purged.
        lightbox.offsetWidth;
        overlay.offsetWidth;

        clonePosition(lightbox, dummy);
      });
    });
  }

  // function ImageLoader (src) {
  //   return new Promise((resolve, reject) => {
  //     let image = new Image();
  //     image.addEventListener('load', () => { resolve(image); });
  //     image.addEventListener('error', (e) => { reject([image, e]); });
  //     image.src = src;
  //   });
  // }

  class Lightbox {
    constructor (element) {
      this.element = element;
      this.src = this.element.src;

      this.anchor = this.element.closest('a');
      if (this.anchor && this.anchor.href.match(/.(?:jpe?g|png)$/)) {
        this.src = this.anchor.href;
      }
      this._setupSpinner();
      this.loaded = false;
    }

    _onload () {
      this.loaded = true;
      this._setPending(false);
      this.show();
    }

    _onerror () {
      this._setPending(false);
    }

    _setupSpinner () {
      if (this.anchor.querySelector('div.figure__spinner')) { return; }
      let html = `<div class="figure__spinner"><span class="figure__spinner-icon"></span></div>`;
      this.anchor.insertAdjacentHTML('beforeend', html);
    }

    _setPending (bool) {
      if (!this.anchor) { return; }
      // Wait a fraction of a second to show the spinner. This way it doesn't
      // get flashed in cases where we already have the image.
      if (bool) {
        this._pendingTimeout = setTimeout(() => {
          this.anchor.setAttribute('data-loading', true);
        }, 200);
      } else {
        clearTimeout(this._pendingTimeout);
        this._pendingTimeout = null;
        this.anchor.removeAttribute('data-loading');
      }
    }

    activate () {
      this._createOverlay();
      if (this.image && this.loaded) {
        return this._onload();
      }

      this._setPending(true);

      this.image = new Image();
      this.image.addEventListener('load', this._onload.bind(this));
      this.image.addEventListener('error', this._onerror.bind(this));
      this.image.src = this.src;
    }

    _createOverlay () {
      this.overlay = createElement('div', {
        'class': 'lightbox__overlay lightbox__overlay--hidden'
      });

      // The overlay does two things: it acts as the modal overlay for the
      // lightbox, and it contains a dummy invisible image that indicates
      // what the real image's size and position should be.
      //
      // Since the dummy image has the same `src` as our image, and is
      // otherwise identically styled except for being invisible, it will have
      // the same aspect ratio.
      this.dummy = createElement('img', {
        src: this.src,
        class: 'lightbox__dummy'
      });
      this.overlay.appendChild(this.dummy);
    }

    show () {
      let { body } = document;

      this.lightbox = createElement('div', { 'class': 'lightbox' });
      this.lightbox.appendChild(this.image);

      body.appendChild(this.overlay);
      body.appendChild(this.lightbox);

      // Force the style batch to be purged.
      this.lightbox.offsetWidth;

      let open = makeLightboxOpener(
        this.lightbox,
        this.element,
        this.overlay,
        this.dummy
      );

      let close = () => { this.hide(); };
      let selector = '.lightbox, .lightbox__overlay';

      this.handlers = {
        close: on(body, 'click', selector, close),
        touch: on(body, 'touchend', selector, close),
        keydown: on(body, 'keydown', (e) => {
          if (e.keyCode !== 27 && e.keyCode !== 32) { return; }
          e.preventDefault();
          close();
        })
      };

      open();

      // When the animation is done, switch to a mode where the lightbox image
      // no longer needs a transform because it's got the same constraints
      // applied to it as were applied to the dummy.
      this.lightbox.addEventListener('transitionend', () => {
        // Save the `transform` value; we'll need to restore it when we reverse
        // the animation later.
        this.lightbox.setAttribute('data-transform', this.lightbox.style.transform);
        this.lightbox.style.transform = '';
        this.lightbox.classList.replace('lightbox--animating', 'lightbox--done');
      });

    }

    hide (immediate) {
      for (let handler of Object.values(this.handlers)) { handler(); }

      // Animate the opacity of the overlay back to 0.
      this.overlay.classList.add('lightbox__overlay--hidden');

      // We need to do everything in reverse. First we restore the transform
      // that we had when the animation ended. We have to do this before
      // swapping class names or else the transform will animate.
      this.lightbox.style.transform = this.lightbox.getAttribute('data-transform');

      // Force the style batch to be purged.
      this.lightbox.offsetWidth;

      // Now we switch back into animation mode.
      this.lightbox.classList.replace('lightbox--done', 'lightbox--animating');

      // Force the style batch to be purged.
      this.lightbox.offsetWidth;

      requestAnimationFrame(() => {
        // Animate the lightbox back to the dimensions of the original element.
        clonePosition(this.lightbox, this.element);
        if (immediate) {
          [this.overlay, this.lightbox].forEach(el => el.remove);
        } else {
          for (let el of [this.overlay, this.lightbox]) {
            el.addEventListener('transitionend', (e) => {
              if (e.target.parentNode) { e.target.remove(); }
            });
          }
        }
      });
    }
  }

  return Lightbox;
})();

(() => {
  function init () {
    on(document.body, 'click', 'figure a img', (e) => {
      e.preventDefault();
      // Ignore if another animation is still happening on the page somewhere.
      if ( document.querySelector('.lightbox') ) { return; }
      let lightbox = new Lightbox(e.target);
      lightbox.activate();
    });
  }

  document.addEventListener('DOMContentLoaded', init);
})();
