/* global LazyLoad */

const SUPPORTS_NATIVE_LAZY_LOAD = 'loading' in HTMLImageElement.prototype;

// We're transitioning from a custom lazy-load solution to the new `loading`
// attribute on images. But this is incredibly hard to polyfill, so we're doing
// this instead.
document.addEventListener('DOMContentLoaded', () => {
  function loadingValueForImg (img) {
    let rect = img.getBoundingClientRect();
    if (rect.y > (window.innerHeight + 100)) {
      // Image is below the fold.
      return 'lazy';
    } else {
      // Image is either within the viewport or has already been scrolled past.
      return 'eager';
    }
  }

  if (SUPPORTS_NATIVE_LAZY_LOAD) {
    // Since the browser supports native lazy-load, we should do some
    // attribute-value-swapping to match what the browser expects.
    let images = document.querySelectorAll('img[data-src], img[data-srcset]');
    images.forEach(img => {
      // We don't want to mark an image with `loading="lazy"` unless it's
      // actually below the fold. Luckily, we know enough at this point to be
      // able to tell the difference, so we can mark anything above the fold as
      // `loading="eager"` instead.
      img.setAttribute('loading', loadingValueForImg(img));

      // With the `loading` attribute set on the image, we're free to copy
      // `data-src` and `data-srcset` over to their equivalents.
      if (img.hasAttribute('data-src')) {
        img.setAttribute('src', img.getAttribute('data-src'));
      }
      if (img.hasAttribute('data-srcset')) {
        img.setAttribute('srcset', img.getAttribute('data-srcset'));
      }
    });
  } else {
    // Browser doesn't support native lazy-load. We'll add the fallback script
    // to the page and instantiate it as soon as it loads.
    let script = document.createElement('script');
    script.async = true;
    script.src = "/wp-content/themes/stag2/js/vendor/lazyload.js";
    script.addEventListener('load', () => {
      window.lazyload = new LazyLoad();
    });
    document.body.appendChild(script);
  }
});

(function () {
  let html = document.documentElement, cn = html.className;
  html.className = cn.replace('no-js', 'js');
})();

document.addEventListener('daub-highlighted', (e) => {
  // When a code block is highlighted, mark its parent PRE tag with the
  // language. This leaves a hint for the comment quoter.
  let element = e.target;
  let { grammar } = e.detail;
  if ( element.parentNode.matches('pre') ) {
    element.parentNode.setAttribute('data-language', grammar.name);
  }
});

import { Highlighter } from 'daubjs';
import * as GRAMMARS from 'daubjs/grammars/index';

import WhitespaceNormalizer from 'daubjs/plugins/whitespace-normalizer';

let highlighter = new Highlighter();
highlighter.addElement(document.body);
for (let g of Object.values(GRAMMARS)) {
  // Treat the JSX grammar as the ordinary JavaScript grammar.
  if (g.name === 'javascript-jsx') {
    g.name = 'js';
    g.names.push('javascript-jsx');
  }
  highlighter.addGrammar(g);
}

WhitespaceNormalizer();

document.addEventListener('DOMContentLoaded', () => highlighter.highlight());

// Let code fire a custom event to signal that it wants a code highlighting
// rescan. That way it doesn't have to import the code higlighter and we can
// do simpler chunk splitting.
document.body.addEventListener('highlighter:rescan', () => {
  highlighter.highlight();
});

import './lightbox';
import './post';

import './i_hate_edge';
