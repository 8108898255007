
export function $ (id) {
  return id.nodeType ? id : document.getElementById(id);
}

export function createElement (tagName, attributes = {}, classNames = []) {
  let node = document.createElement(tagName);
  for (let attr in attributes) {
    node.setAttribute(attr, attributes[attr]);
  }
  classNames.forEach(cn => node.classList.add(cn));
  return node;
}

export function fireEvent (element, eventName, detail = null) {
  let event = new CustomEvent(eventName, { bubbles: true, detail });
  return element.dispatchEvent(event);
}

// A port of Prototype's Element#on.
export function on (element, eventName, selector, handler) {
  if (typeof selector === 'function' && !handler) {
    handler = selector;
    selector = null;
  }
  let wrappedHandler = selector ? (event, ...args) => {
    let closest = event.target.closest(selector);
    if (!closest) { return; }
    handler(event, ...args);
  } : handler;
  element.addEventListener(eventName, wrappedHandler);
  return () => element.removeEventListener(eventName, wrappedHandler);
}

export function toggleElement (el, bool) {
  if (typeof bool === 'undefined') { bool = el.style === 'none'; }
  el.style.display = bool ? '' : 'none';
}
